<template>
  <div class="row">
    <div class="col-12 card">
      <div class="card-header">
        <ul class="steps">
          <template v-for="(tab, index) in tabs">
            <li class="step-item" :class="{ active: isActive(tab.route), disabled: isDisabled(tab.route) }" v-bind:key="index">
              <span :to="{ name: tab.route }" class="step-link" active-class="active">
                <span class="step-number">{{ index+1 }}</span>
                <span class="step-title">{{ tab.name }}</span>
              </span>
            </li>
          </template>
        </ul>
      </div>
      <div class="card-body">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    middleware: 'auth',
    computed: {
      tabs () {
        return [
          {
            name: this.$t('invite.tab'),
            route: 'accounts.church.invite'
          },
          {
            name: this.$t('invite.church_tab'),
            route: 'accounts.church.create'
          }
        ]
      }
    },
    methods: {
      isActive(name) {
        return this.$route.name === name;
      },
      isDisabled(name) {
        return this.$route.name === 'accounts.church.invite' && name === 'accounts.church.create';
      }
    }
  }
</script>

<style>
  .settings-card .card-body {
    padding: 0;
  }
</style>
